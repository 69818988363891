<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="wet-section">
      <div class="container">
        <div class="wet-section-title text-center">
          <h1 class="empty-ph">
            {{headerTitle}}
          </h1>
          <p v-if="setPasswordFlag">
            {{str.passwordSet.description}}
          </p>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-8 col-lg-5">
            <form @submit.prevent="submit">
              <div class="wet-input wet-input-with-label">
                <input
                  id="wet-input-password"
                  type="password"
                  :placeholder="str.login.password"
                  v-model.trim="$v.form.password.$model"
                >
                <label for="wet-input-password">
                  {{ str.login && str.login.password }}
                </label>
              </div>
              <div
                v-for="(error, i) in passwordErrors"
                :key="i"
                class="small text-primary mt-n2 mb-3 mx-3"
              >
                {{ error }}
              </div>
              <div class="wet-input wet-input-with-label">
                <input
                  id="wet-input-re-password"
                  type="password"
                  :placeholder="str.login.password_retype"
                  v-model.trim="$v.form.passwordRetype.$model"
                >
                <label for="wet-input-re-password">
                  {{ str.login.password_retype }}
                </label>
              </div>
              <div
                v-for="(error, i) in rePasswordErrors"
                :key="`re-${i}`"
                class="small text-primary mt-n2 mb-3 mx-3"
              >
                {{ error }}
              </div>
              <div class="d-flex">
                <button v-if="!setPasswordFlag"
                  id="back-button"
                  type="button"
                  class="wet-button wet-button-flat empty-ph mr-3"
                  style="min-width:4rem;"
                  @click.prevent="back"
                >{{ str.commonApp.back_text }}</button>
                <button
                  id="wet-button-signup"
                  type="submit"
                  class="wet-button empty-ph flex-fill"
                  :disabled="submitDisabled"
                >{{ buttonStr }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';
import {
  password,
  passwordRetype,
} from '@/common/validations';
import wpSection from '@/enums/wpSection';
import {
  passwordReset,
  passwordSet,
  commonApp,
  login,
} from '@/enums/stringIndices';
import LogService from '@/services/LogService';

export default {
  mixins: [common],
  data: () => ({
    resetPwdApi: '/api/user/reset_password',
    form: {
      password: undefined,
      passwordRetype: undefined,
    },
    str: {
      passwordReset,
      commonApp,
      login,
      passwordSet,
    },
    submitDisabledManually: false,
  }),
  computed: {
    ...mapState(['strings', 'loading']),
    availableErrors() {
      return {
        // required: this.loginStrings.error_required,
        sameAs: this.str.login.error_password_identical,
        minLength: this.str.login.error_password_rules,
        hasOneNum: this.str.login.error_password_rules,
        hasOneLetter: this.str.login.error_password_rules,
      };
    },
    submitDisabled() {
      return this.$v.$invalid || this.submitDisabledManually;
    },
    setPasswordFlag() {
      LogService.log(
        'magic_code',
        this.$route.query.magic_code,
        this.$route.query.magic_code !== undefined,
      );
      return this.$route.query.magic_code !== undefined;
    },
    rePasswordErrors() {
      return this.getErrors('passwordRetype');
    },
    passwordErrors() {
      return this.getErrors('password');
    },
    resetToken() {
      return this.$route.query.token;
    },
    headerTitle() {
      return this.setPasswordFlag
        ? this.str.passwordSet.header_title : this.str.passwordReset.header_title;
    },
    buttonStr() {
      return this.setPasswordFlag
        ? this.str.passwordSet.button : this.str.passwordReset.button;
    },
  },
  validations() {
    return {
      form: {
        password,
        passwordRetype,
      },
    };
  },
  methods: {
    async init() {
      const metaPage = this.setPasswordFlag ? wpSection.PASSWORD_SET : wpSection.PASSWORD_RESET;
      await this.getWordPressStrings(
        [wpSection.LOGIN, wpSection.PASSWORD_RESET, wpSection.PASSWORD_SET, wpSection.COMMON_APP],
        metaPage,
      );
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) return;
      this.submitPasswordReset(this.setPasswordFlag);
    },
    submitPasswordReset(setPassword = false) {
      const func = setPassword ? this.setPwd : this.resetPwd;

      let message = '';
      let result = false;
      this.submitDisabledManually = true;
      func()
        .then(() => {
          message = setPassword
            ? this.str.passwordSet.success : this.str.passwordReset.success_status;
          result = true;
        })
        .catch(() => {
          message = setPassword
            ? this.str.passwordSet.error : this.str.passwordReset.error_status;
        })
        .finally(() => {
          this.submitDisabledManually = false;
          const modal = {
            id: 'wet-reset-pwd-modal',
            content: `<div class="text-center">${message}</div>`,
            actions: [
              {
                name: 'wet-reset-pwd-modal-ok',
                title: this.str.commonApp.ok,
                event: 'close',
              },
            ],
            close: result && this.onClickModalOk,
          };
          this.$store.dispatch('addModal', modal);
        });
    },
    submitPasswordSet() {
      let message = '';
      let result = false;
      this.submitDisabledManually = true;
      this.setPwd()
        .then(() => {
          message = this.str.passwordReset.success_status;
          result = true;
        })
        .catch(() => {
          message = this.str.passwordReset.error_status;
        })
        .finally(() => {
          this.submitDisabledManually = false;
          const modal = {
            id: 'wet-reset-pwd-modal',
            content: `<div class="text-center">${message}</div>`,
            actions: [
              {
                name: 'wet-reset-pwd-modal-ok',
                title: this.str.commonApp.ok,
                event: 'close',
              },
            ],
            close: result && this.onClickModalOk,
          };
          this.$store.dispatch('addModal', modal);
        });
    },
    getErrors(field) {
      const errors = [];
      Object.keys(this.$v.form[field].$params).forEach((key) => {
        if (!this.$v.form[field][key] && this.availableErrors[key]
          && !errors.includes(this.availableErrors[key])
        ) {
          errors.push(this.availableErrors[key]);
        }
      });
      return errors;
    },
    async resetPwd() {
      const data = {
        password: this.form.password,
        resetToken: this.resetToken,
      };
      await this.$webAppAPI.callResetPassword(data);
    },
    async setPwd() {
      const data = {
        password: this.form.password,
        resetToken: this.resetToken,
      };
      const headers = {
        'Magic-Code': this.$route.query.magic_code,
      };
      LogService.log('setPwd', data, headers);
      return this.$webAppAPI.callSetPassword(data, headers);
    },
    back() {
      this.$router.back();
    },
    onClickModalOk() {
      if (this.setPasswordFlag && this.$route.query.order_id) {
        const orderId = this.$route.query.order_id;
        this.$router.push({ query: null, path: `insurance/${orderId}`, params: { ...this.$route.params } });
      } else {
        this.$router.push({ query: null, path: 'insurance', params: { ...this.$route.params } });
      }
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style>

</style>
